import { graphql } from 'gatsby';
import { get } from 'lodash';
import React from 'react';

import { getSchema } from '../common/schema';
import { ModuleHeading } from '../components/blocks/moduleHeading';
import { ModuleUpdateList } from '../components/blocks/moduleUpdateList';
import { SEO } from '../components/common/seo';
import { Theme } from '../theme/color';

export const query = graphql`
  query PageMUpdateQuery($id: String!, $originalId: String!, $locale: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageUpdate(id: { eq: $id }) {
      model {
        apiKey
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
    }
    updates: allDatoCmsItemUpdateList(
      filter: { locale: { eq: $locale }, title: { ne: null } }
    ) {
      nodes {
        ...ItemUpdateList
      }
    }
    versions: allDatoCmsPageModular(
      filter: { slug: { ne: null }, originalId: { eq: $originalId } }
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const PageUpdate = ({ data, pageContext }) => {
  const settings = get(data, 'settings.siteMetadata');
  const page = get(data, 'page');
  const site = get(data, 'site');
  const versions = get(data, 'versions.nodes');
  const updates = get(data, 'updates.nodes');

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  return (
    <>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        isArticle={false}
        page={page}
        versions={versions}
      />
      <ModuleHeading
        theme={Theme.WHITE}
        text={page.title}
        innerPageTitle
        anchor="heading"
      />
      <ModuleUpdateList
        theme={Theme.WHITE}
        items={updates}
        collapsible={false}
      />
    </>
  );
};

export default PageUpdate;
